import React, { createContext, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Home from './components/Home/Home';
import Privecy from './components/Privecy/Privecy';




export const UserContext = createContext();

function App(props) {
  const [loggedInUser, setLoggedInUser] = useState({});
  return (
    <UserContext.Provider value={[loggedInUser, setLoggedInUser]}>
      <Router>
        <Switch>
          <Route exact path="/" >
          <Home/>
          </Route>
          <Route path="/privacypolicy" >
            <Privecy></Privecy>
          </Route>
        </Switch>
      </Router>
    </UserContext.Provider>
  );
}

export default App;
