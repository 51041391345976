import React, { useState } from "react";
import "./Home.css";

const App = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openGooglePlay = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.bdschoolghor",
      "_blank"
    );
  };

  return (
    <div className="app">
      <h1 className="header">welcome to school ghor</h1>
      <div className="button-container">
        <button className="action-button" onClick={openGooglePlay}>
          Open Play Store
        </button>
        <button className="action-button" onClick={openModal}>
          About
        </button>
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <p>
            In Bangladesh typically, when someone seeks admission in educational institutions, they have to search for information separately on various educational institutions' websites, apps, or by visiting the institutions in person. This fragmented process often leads to time wastage and missing out on details about quality institutions. To solve this problem School Ghor – this app that provides centralized information about educational institutions.
            Through this app, users can access details such as admission criteria – all from the comfort of their homes. It is highly beneficial for citizens. Initially launched as a web application in 2020, it was later introduced as an Android app in 2022. Until April 2023, School Ghor remained in a basic structural form with no active operations. In May 2023, School Ghor App began limited operations in Sylhet, Bangladesh with a limited database, and this operation continues.
            </p>
            <p><strong>Email:</strong> contact@schoolghor.xyz</p>
            <p>
              <strong>Note:</strong> Currently, no activities are being
              conducted on our website. Download our app to view and use our
              activities.
            </p>
            <button className="close-button" onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
